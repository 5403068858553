<template>
  <div
    class="sticky top-0 z-50 mx-auto flex h-[80px] w-full items-center gap-3 border-b border-[#E2E8F0] bg-[#F8F9FA] md:w-[96%]"
  >
    <div class="flex items-center gap-x-1 lg:hidden">
      <div>
        <Button variant="outline" size="icon" @click.stop="handleOpenSidebar">
          <the-icon icon-name="menu" size="xsm" />
        </Button>
      </div>
      <div>
        <the-icon icon-name="logo" style="width: 90px" />
      </div>
    </div>
    <div
      v-if="showActions"
      class="top-0 flex h-full flex-grow items-center justify-between gap-1"
    >
      <div v-if="!!organisationId" class="hidden lg:flex">
        <TooltipProvider :delay-duration="500">
          <Tooltip>
            <TooltipTrigger as-child>
              <mail-box />
            </TooltipTrigger>
            <TooltipContent :avoid-collisions="true">
              <p class="pb-1 text-xs font-semibold text-gray-700">
                Click to copy your forwarding address
              </p>
              <p class="text-xs font-normal text-gray-700">
                Please forward all your invoices to this email address to <br />
                be automatically added to your Lenkie Bills.
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <GeneralSearchInput
        v-if="!!organisationId && !isLoading && isNotBillsPage"
        class="hidden lg:block"
        @selected-bill-from-search="handleOpenBillFromSearch"
      />
      <DropdownMenu
        v-if="
          organisationId &&
          !isLoading &&
          permissionsNeededHere.canCreateSomething
        "
      >
        <DropdownMenuTrigger
          :disabled="!organisationId"
          class="ml-auto flex-shrink-0"
        >
          <Button
            :disabled="!organisationId"
            variant="default"
            class="flex items-center gap-1 pr-0 md:gap-2"
            data-test-id="add-new-item-btn"
          >
            <the-icon icon-name="plus" size="xs" />
            Add new
            <div class="ml-1 h-full border-r border-[#3B4B72]"></div>
            <div class="mr-3">
              <the-icon icon-name="chevron-down" size="xs" />
            </div>
            <DropdownMenuContent class="mr-5 mt-1 w-[155px] md:w-[165px]">
              <template v-for="x in info" :key="x.id">
                <DropdownMenuItem
                  v-if="x.show"
                  class="w-full text-primary"
                  as="button"
                  @click.prevent="getActiveType(x.type)"
                >
                  <the-icon
                    :icon-name="x.iconName"
                    size="xs"
                    class-name="mr-2"
                  />
                  {{ x.name }}</DropdownMenuItem
                >
              </template>
            </DropdownMenuContent>
          </Button>
        </DropdownMenuTrigger>
      </DropdownMenu>
      <template v-if="sellerId">
        <AddVendor :open="openAddVendor" @close="closeVendor" />
      </template>
    </div>

    <ModulesBillsBillSummary
      :is-fetching-bill="false"
      :bill="selectedBill"
      :open="openBillSummary"
      @close="handleCloseBillSummary"
    />
    <BaseModal
      :show-heading="false"
      :show="showOpenNewBillError"
      size="md"
      @close-modal="showOpenNewBillError = false"
    >
      <ModulesBillsNewBillFeatureAlertMessage class="pb-2" />
    </BaseModal>
    <BaseModal
      :show="showBulkBillUploadModal"
      :show-heading="false"
      padding="p-8"
      size="6xl"
      :can-close="!bulkBillUploadModalIsBusy"
      @close-modal="showBulkBillUploadModal = false"
    >
      <ModulesBillsBulkBillsIntro
        @is-busy="(val) => (bulkBillUploadModalIsBusy = val)"
        @success="showBulkBillUploadModal = false"
      />
    </BaseModal>
  </div>
</template>

<script setup lang="ts">
// import SearchInput from './search-input.vue'
import { useQuery } from '@tanstack/vue-query'
import MailBox from './mail-box.vue'
import GeneralSearchInput from './general-search-input.vue'
import TheIcon from '@/components/shared/the-icon.vue'
import { Button } from '@/components/ui/button'
import { useProfileStore } from '@/stores/profile'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import AddVendor from '@/components/modules/vendors/add-vendor.vue'
import type { Data } from '~/plugins/eventBus'
import { setModalState } from '~/services/modal'
import type { Invoice } from '~/shared/interfaces'
import { FeatureCodes } from '~/network/core/featureFlag'

interface Props {
  showActions?: boolean
}

withDefaults(defineProps<Props>(), { showActions: true })
const openAddVendor = ref(false)

const openBillSummary = ref(false)

function handleCloseBillSummary() {
  openBillSummary.value = false
  billsStore.$patch({ selectedBill: null })
}

const { $listen, $event, $api } = useNuxtApp()

const profileStore = useProfileStore()
const sellerId = computed(() => profileStore.data.sellerId)

const route = useRoute()
const isNotBillsPage = computed(() => !route.path.includes(routes.bills))

$listen('open:vendor', ({ trigger }: Data) => {
  if (trigger) {
    openAddVendor.value = true
  }
})

interface IInfo {
  id: number
  type: 'bill' | 'vendor' | 'bulk'
  name: string
  iconName: string
  show: boolean
}

const info = computed<IInfo[]>(() => {
  return [
    {
      id: 1,
      type: 'bill',
      name: 'Single bill',
      iconName: 'file',
      show: true,
    },
    {
      id: 2,
      type: 'bulk',
      name: 'Bulk bill upload',
      iconName: 'bill-file-upload',
      show: true,
    },
    {
      id: 3,
      type: 'vendor',
      name: 'New vendor',
      iconName: 'user',
      show: true,
    },
  ]
})

const organisationId = computed(() => profileStore?.data?.organisation?.id)

const getActiveType = (val: 'bill' | 'vendor' | 'bulk') => {
  if (val === 'vendor') {
    openAddVendor.value = true
    $event('track:mixpanel', {
      event: 'New Vendor clicked',
      data: {},
    })
  } else if (val === 'bill') {
    if (!canUseNewBillFeature.value) {
      showOpenNewBillError.value = true
      return
    }
    setModalState({ isOpen: true, type: 'newBill' })
    $event('track:mixpanel', {
      event: 'New Bill clicked',
      data: {},
    })
  } else if (val === 'bulk') {
    $event('track:mixpanel', {
      event: 'Bulk bills selected',
      data: {},
    })
    showBulkBillUploadModal.value = true
  }
}
const showBulkBillUploadModal = ref(false)
const bulkBillUploadModalIsBusy = ref(false)

const billsStore = useBillsStore()

const selectedBill = computed(() => billsStore.selectedBill)

const handleOpenSidebar = () => {
  $event('open:sidebar', { trigger: true })
}

function handleOpenBillFromSearch(bill: Invoice) {
  billsStore.$patch({ selectedBill: bill })
  openBillSummary.value = true
}

const closeVendor = () => {
  openAddVendor.value = false
}

const showOpenNewBillError = ref(false)

const hasOrganisationId = computed(() => !!organisationId.value)

const { data: hasAccessToEmailFeature, isLoading: isLoading1 } = useQuery({
  queryKey: ['has-access-to-feature', { organisationId }],
  queryFn: () =>
    $api.core.featureFlag.checkIfOrgHasAccessToFeature(
      organisationId.value!,
      FeatureCodes.EMAIL_INVOICE,
    ),
  enabled: hasOrganisationId,
  select(data) {
    return data.data.result
  },
})

const { data: interactionCount, isLoading: isLoading2 } = useQuery({
  queryKey: ['getFeatureCount', 'EMAIL_INVOICE', { organisationId }],
  queryFn: () =>
    $api.core.featureFlag.getInteractionCountOfAFeatureByOrganization(
      organisationId.value!,
      FeatureCodes.EMAIL_INVOICE,
    ),
  select(data) {
    return data.data.result?.tracker_count
  },
  enabled: hasOrganisationId,
})
const isLoading = computed(() => isLoading1.value || isLoading2.value)

const canUseNewBillFeature = computed(() => {
  if (!hasAccessToEmailFeature.value) return true
  if (!interactionCount.value) return false
  return interactionCount.value >= 5
})

watch(
  canUseNewBillFeature,
  (newVal) => {
    billsStore.$patch({ canUseNewBillFeature: newVal })
  },
  { immediate: true },
)

const { currentPermissionsIds } = useUserRole()

const permissionsNeededHere = computed(() => {
  const permissionsSet = new Set(currentPermissionsIds.value)

  return {
    canCreateSomething:
      permissionsSet.has('Bills.CreateBill') ||
      permissionsSet.has('Suppliers.CreateSupplier'),
    canMakePayment: permissionsSet.has('OpenBanking.MakePayment'),
  }
})
</script>
